import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { useEffect } from 'react';
import getValueFromNestedKeys from 'utils/formats/formInputs';

type FormCheckboxMuiProps = Omit<
  FormControlLabelProps,
  'control' | 'checked' | 'label'
> & {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
};

const FormCheckboxMui = ({
  name,
  label = '',
  formik,
  labelPlacement = 'top',
  onChange,
  ...props
}: FormCheckboxMuiProps) => {
  const { values, setFieldValue } = formik;
  const defaultOnChange = (e) => setFieldValue(name, e.target.checked);
  const checkboxValue = Boolean(getValueFromNestedKeys(values, name));

  useEffect(() => {
    setFieldValue(name, checkboxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControlLabel
      checked={checkboxValue}
      control={<Checkbox />}
      label={label}
      labelPlacement={labelPlacement}
      onChange={onChange || defaultOnChange}
      {...props}
    />
  );
};

export default FormCheckboxMui;
