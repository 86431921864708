import { ConfigProvider, DatePicker } from 'antd';
import defaultLocale from 'antd/lib/locale/de_DE';
import moment from 'moment';

import useTranslation from 'next-translate/useTranslation';
import FormItem from './FormItem';

const FormTimePicker = ({
  name,
  locale = defaultLocale,
  placeholder = '',
  defaultValue,
  label,
  format = 'DD.MM.YYYY',
  value = new Date(),
  onChange,
  ...restProps
}) => {
  const { lang } = useTranslation();
  const isGerman = lang === 'de';
  // TODO - next-translate has a better way of handling this
  format = isGerman ? 'DD.MM.YYYY' : 'YYYY-MM-DD';
  return (
    <FormItem name={name} label={label}>
      <ConfigProvider locale={locale}>
        <DatePicker
          name={name}
          format={format}
          defaultValue={moment(defaultValue, format)}
          value={value ? moment(value) : null}
          onChange={(_, dateString) => {
            const date = moment(dateString, format).format('YYYY-MM-DD');
            onChange(name, date);
          }}
          {...restProps}
        />
      </ConfigProvider>
    </FormItem>
  );
};

export default FormTimePicker;
