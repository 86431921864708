import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useLanguage } from 'hooks/translations/useLanguage';
import { Moment } from 'moment';
import { useState } from 'react';

const FormDatePickerMui = ({
  name,
  label,
  value = null,
  formik,
  onChange = null,
  fullWidth = false,
  required = false,
  showPickerOnFocus = true,
  ...props
}) => {
  const { lang } = useLanguage();

  const { values, errors, setFieldValue } = formik;
  const isGerman = lang === 'de';

  const [open, setOpen] = useState(false);

  const handleOpen = () => showPickerOnFocus && setOpen(true);
  const handleClose = () => showPickerOnFocus && setOpen(false);

  const defaultOnChange = (newValue: Moment) =>
    setFieldValue(name, newValue.format('YYYY-MM-DD'));

  return (
    <DesktopDatePicker
      open={showPickerOnFocus ? open : undefined}
      onOpen={handleOpen}
      onClose={handleClose}
      label={label}
      value={value || values[name] || null}
      onChange={onChange || defaultOnChange}
      inputFormat={isGerman ? 'DD.MM.YYYY' : 'YYYY-MM-DD'}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Boolean(errors[name])}
            helperText={errors[name]}
            fullWidth={fullWidth}
            required={required}
            onClick={handleOpen}
            sx={{
              // make all childs box sizing unset
              '& *': { boxSizing: 'unset !important' },
            }}
          />
        );
      }}
      {...props}
    />
  );
};

export default FormDatePickerMui;
