import { TimePicker } from 'formik-antd';
import FormItem from './FormItem';

const FormTimePicker = ({
  name,
  validate,
  fast,
  label,
  format = 'HH:mm',
  ...props
}) => (
  <FormItem name={name} label={label} {...props}>
    <TimePicker name={name} format={format} />
  </FormItem>
);

export default FormTimePicker;
