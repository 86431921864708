import { Spin } from 'antd';

const SpinLoader = ({ loading, height = '280px', children }) =>
  loading ? (
    <Spin spinning>
      <div style={{ width: '100%', height }} />
    </Spin>
  ) : (
    children
  );

export default SpinLoader;
