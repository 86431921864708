import { Input } from 'formik-antd';

import FormItem from './FormItem';

import styles from 'assets/main.module.less';

const { Password } = Input;

const FormInput = ({
  name,
  testId = null,
  placeholder = '',
  label = null,
  disabled = false,
  type = 'text',
  min = null,
  onChange = null,
  addonBefore = null,
  maxLength = null,
  ...props
}) => (
  <FormItem className={styles.label_input} name={name} label={label}>
    {type === 'password' ? (
      <Password
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    ) : (
      <Input
        className={styles.input_arrimo}
        data-testid={testId}
        maxLength={maxLength}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        type={type}
        min={min}
        onChange={onChange}
        addonBefore={addonBefore}
        {...props}
      />
    )}
  </FormItem>
);

export default FormInput;
