import { Input } from 'formik-antd';

import FormItem from './FormItem';

const { TextArea } = Input;

const FormTextArea = ({
  name,
  testId = null,
  placeholder = '',
  label = null,
  disabled = false,
  type = 'text',
  min = null,
  maxLength = 2000,
  minRows = null,
  maxRows = null,
}) => (
  <FormItem name={name} label={label}>
    <TextArea
      testId={testId}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      type={type}
      maxLength={maxLength}
      min={min}
      autoSize={{ minRows, maxRows }}
    />
  </FormItem>
);

export default FormTextArea;
