import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { useTranslations } from 'hooks/translations/useTranslations';
import { useEffect } from 'react';

import getValueFromNestedKeys from 'utils/formats/formInputs';

type FormSelectMuiProps = SelectProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  name: string;
  label: string;
  value?: string;
  optionValue?: string;
  optionDesc?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  defaultValue?: string;
};

const FormSelectMui = ({
  items,
  name,
  label,
  value = '',
  optionValue = 'id',
  optionDesc = 'name',
  formik,
  onChange = null,
  defaultValue = '',
  ...props
}: FormSelectMuiProps) => {
  const {
    common: { t },
  } = useTranslations();
  const { values = [], setFieldValue = () => {}, errors = [] } = formik || {};
  const defaultOnChange = (e) => setFieldValue(name, e.target.value);
  const inputValue = formik
    ? getValueFromNestedKeys(values, name) || defaultValue || ''
    : value || defaultValue || '';

  useEffect(() => {
    setFieldValue(name, inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth error={Boolean(errors[name])}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        label={label}
        name={name}
        onChange={onChange || defaultOnChange}
        value={inputValue}
        sx={{
          // make all childs box sizing unset
          '& *': { boxSizing: 'unset !important' },
        }}
        {...props}
      >
        {
          <MenuItem
            value=""
            sx={{
              // make same styles as label
              color: 'rgba(0, 0, 0, 0.54)',
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: 1.43,
              letterSpacing: '0.01071em',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            {label}
          </MenuItem>
        }
        {items?.length > 0 &&
          items.map((item) => (
            <MenuItem value={item[optionValue]} key={item[optionValue]}>
              {t(String(item[optionDesc]))}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{errors[name]}</FormHelperText>
    </FormControl>
  );
};

export default FormSelectMui;
