import { Collapse } from 'antd';

const FormCollapses = ({
  activeKeys,
  onChange,
  collapsesKeys,
  collapses,
  t,
}) => (
  <>
    {Object.values(collapsesKeys).map((key, index) => (
      <Collapse
        activeKey={activeKeys}
        bordered={false}
        expandIconPosition="end"
        onChange={() => onChange(key)}
        style={{ marginBottom: '16px', marginTop: '16px' }}
        key={`${key}-${index}`}
      >
        <Collapse.Panel header={t(key)} key={'' + key}>
          {collapses['' + key]}
        </Collapse.Panel>
      </Collapse>
    ))}
  </>
);

export default FormCollapses;
