import { Checkbox } from 'formik-antd';

import FormItem from './FormItem';

const FormCheckbox = ({
  name,
  disabled = false,
  children,
  label = null,
  ...props
}) => (
  <FormItem name={name} label={label}>
    <Checkbox disabled={disabled} name={name} {...props}>
      {children}
    </Checkbox>
  </FormItem>
);

export default FormCheckbox;
