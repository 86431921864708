import { Form } from 'formik-antd';

const { Item } = Form;

const FormItem = ({ children, name = null, label = null, ...props }) => (
  <Item name={name} label={label} labelCol={{ span: 24 }} {...props}>
    {children}
  </Item>
);

export default FormItem;
