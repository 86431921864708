import { Select } from 'formik-antd';
import { useTranslations } from 'hooks/translations/useTranslations';

import FormItem from './FormItem';
import { SelectProps } from 'antd';

const { Option } = Select;

type FormSelectProps = SelectProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource?: any[];
  name?: string;
  label?: string;
  disabled?: boolean;
  mode?: string;
  loading?: boolean;
  optionKey?: string;
  optionDesc?: string;
  optionValue?: string;
  withFirstOption?: boolean;
  firstOptionValue?: string;
  firstOptionDesc?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customRender?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleSelect?: any;
  placeholder?: string;
  showSearch?: boolean;
  optionFilterProp?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOption?: any;
  dataTestId?: string;
  sort?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customSort?: any;
  withTranslation?: boolean;
};

const FormSelect = ({
  dataSource = [],
  name = '',
  label = null,
  disabled = false,
  mode = null,
  loading = false,
  optionKey = 'id',
  optionDesc = 'name',
  optionValue = 'id',
  withFirstOption = false,
  firstOptionValue = null,
  firstOptionDesc = '',
  customRender = false,
  onChange = null,
  styleSelect = null,
  placeholder = null,
  showSearch = false,
  optionFilterProp = 'children',
  filterOption = null,
  dataTestId = null,
  sort = false,
  customSort,
  withTranslation = true,
  ...props
}: FormSelectProps) => {
  const {
    common: { t },
  } = useTranslations();

  if (sort) {
    dataSource = dataSource.sort((a, b) =>
      a[optionDesc] > b[optionDesc] ? 1 : a[optionDesc] < b[optionDesc] ? -1 : 0
    );
  }

  if (typeof customSort === 'function') {
    dataSource = dataSource.sort(customSort);
  }

  return (
    <FormItem name={name} label={label}>
      <Select
        mode={mode}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={dataTestId}
        disabled={disabled}
        showSearch={showSearch}
        loading={loading}
        style={styleSelect}
        optionFilterProp={optionFilterProp}
        filterOption={filterOption}
        {...props}
      >
        {withFirstOption ? (
          <Option value={firstOptionValue}>
            <div style={{ color: '#bfbfbf' }}>{firstOptionDesc}</div>
          </Option>
        ) : null}
        {dataSource?.map((data) => (
          <Option value={data[optionValue]} key={`${name}_${data[optionKey]}`}>
            {typeof customRender === 'function'
              ? customRender(data)
              : withTranslation
              ? t(data[optionDesc])
              : data[optionDesc]}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default FormSelect;
