import { Form, Input } from 'formik-antd';
import { memo } from 'react';

const Street = ({ withLabels = false, disabled = false, namePrefix = '' }) => (
  <Form.Item
    name={`${namePrefix}street`}
    style={style}
    label={withLabels ? 'Calle' : null}
  >
    <Input
      name={`${namePrefix}street`}
      disabled={disabled}
      placeholder="Calle"
    />
  </Form.Item>
);

const style = {
  marginBottom: '5px',
};

export default memo(Street);
