import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type ArrimoButtonProps = LoadingButtonProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
};

const ArrimoButton = ({ children, sx = {}, ...props }: ArrimoButtonProps) => (
  <LoadingButton
    color="primary"
    variant="contained"
    sx={{ ...style, ...sx }}
    {...props}
  >
    {children}
  </LoadingButton>
);

const style = {
  p: '.625rem 1.25rem',
  fontFamily: 'Tektur',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 16,
  textTransform: 'none',
  borderRadius: 2,
};

export default ArrimoButton;
