import { Empty } from 'antd';

const CustomEmpty = ({ description }) => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={`${description || 'No data'}`}
  />
);

export default CustomEmpty;
