import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useEffect, useRef } from 'react';
import getValueFromNestedKeys from 'utils/formats/formInputs';
import { NumericFormat } from 'react-number-format';

type FormInputMuiProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
  name?: string;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  hideArrows?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  isCurrency?: boolean;
};

const FormInputMui = ({
  formik = {},
  name,
  icon = null,
  iconPosition = 'start',
  value,
  onChange,
  hideArrows = false,
  isCurrency,
  ...props
}: FormInputMuiProps) => {
  const { values = [], errors = [], setFieldValue = () => {} } = formik;
  const textFieldRef = useRef();

  const defaultOnChange =
    props.type === 'number'
      ? (e) => setFieldValue(name, +e.target.value)
      : (e) => setFieldValue(name, e.target.value);

  const inputValue =
    value ?? getValueFromNestedKeys(values, name) ?? props.defaultValue ?? '';

  useEffect(() => {
    setFieldValue(name, inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };
    textFieldRef?.current?.addEventListener('wheel', handleWheel);

    return () => {
      textFieldRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <TextField
      value={inputValue}
      ref={textFieldRef}
      name={name}
      error={Boolean(errors[name])}
      helperText={errors[name]}
      onChange={onChange || defaultOnChange}
      inputProps={{
        maxLength: props?.maxLength,
        min: props?.min,
        max: props?.max,
        step: props?.step,
      }}
      InputProps={{
        inputComponent: isCurrency ? NumberFormatCustom : undefined,
        startAdornment:
          icon && iconPosition === 'start' ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : undefined,
        endAdornment:
          icon && iconPosition === 'end' ? (
            <InputAdornment position="end">{icon}</InputAdornment>
          ) : undefined,
      }}
      sx={{
        // make all childs box sizing unset
        '& *': { boxSizing: 'unset !important' },
        // if number remove arrows
        ...(hideArrows
          ? {
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }
          : {}),
      }}
      {...props}
    />
  );
};

// Custom NumberFormat component
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'} // German thousand separator
      decimalSeparator={','} // German decimal separator
      prefix={'€'} // Currency symbol
      isNumericString
    />
  );
}

export default FormInputMui;
