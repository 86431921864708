import { Switch } from 'formik-antd';
import FormItem from './FormItem';

const SwitchInput = ({
  name,
  label = null,
  disabled = false,
  value = true,
  onChange = null,
  style = null,
  size = 'default',
  testId = null,
}) => (
  <FormItem name={name} label={label} style={style}>
    <Switch
      data-testid={testId}
      disabled={disabled}
      name={name}
      value={value}
      onChange={onChange}
      size={size}
    />
  </FormItem>
);

export default SwitchInput;
